/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Divider,
	FormControl,
	Grid,
	IconButton,
	InputAdornment,
	ListItemIcon,
	MenuItem,
	Select,
	Tab,
	Tabs,
	TextField,
	Tooltip
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import i18n from "i18next";
import React, { memo, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import AttachmentsContainer from "../../../helpers/AttachmentsContainer";
import AttachmentsContainerHistory from "../../../helpers/AttachmentsContainerHistory";
import GetFlag from "../../../helpers/GetFlag";
import settings from "../../../helpers/settingFunctions";
import { downloadColdFile, getACleanURI } from "../../../helpers/utilities";
import cockpitActions from "../../../redux/actions/cockpitActions";
import C from "../../../constants/cockpit";
import { getCockpitTabInfoSelector } from "redux/selectors/cockpitSelectors";
import { ColdTabInfoTabs } from "../Convs/cold/TabInfoTabsCOLD";
import NotesComponent, { NoteInputComponent } from "./Notes";
import { Infos } from "./Infos";
import { ActionsComponent } from "./Actions";
import { TabInfoTabsCALL } from "../Convs/call/TabInfoTabsCALL";
import HistoricList from "helpers/HistoricList";
import {
	AppsOutlined,
	AssignmentOutlined,
	AttachFileOutlined,
	InfoOutlined,
	ReplyAllOutlined
} from "@material-ui/icons";
import { setAnswerLanguage } from "redux/reducers/answersReducers";
import { setContactAnsDocSearchFilter } from "redux/reducers/answersReducers";
import { Answers } from "./Answers";
import { SET_LOCALIZED_SCHEMAS } from "@jsonforms/core";
import ContextCallinterface from "../Convs/call/CallInterface";
import lod_ from "lodash";

const CockpitTabInfo = ({ setSnackbarStatus, xs, history, historyBACK }) => {
	const dispatch = useDispatch();
	let { conversations, selectedConversation, convHistory, userStatus, assistantconfig, isCold } =
		useSelector(getCockpitTabInfoSelector, shallowEqual);
	const languages = useSelector(state => state.assistantconfig?.languages?.map(l => l.language));
	const { language, contactAnsDocSearchFilter, data } = useSelector(state => state.answers);
	const [rightTab, setRightTab] = useState(isCold ? 2 : 0);
	const [sntAtt, setSntAtt] = useState([]);
	const [sntAttAll, setSntAttAll] = useState([]);
	const [rcvAtt, setRcvAtt] = useState([]);
	const [rcvAttAll, setRcvAttAll] = useState([]); //arr to download all attachments received
	const [histRcvAtt, setHistRcvAtt] = useState([]);
	const [histRcvAttAll, setHistRcvAttAll] = useState([]); //arr to download all attachments received from history
	const [attachmentsRcvFiltered, setAttachmentsRcvFiltered] = useState([]);
	const [attachmentsSntFiltered, setAttachmentsSntFiltered] = useState([]);
	const [tagsResponses, setTagsResponses] = useState([]);
	const [tag, setTag] = useState("none");
	const [activeInlineRcvFilter, setActiveInlineRcvFilter] = useState(true);
	const [activeInlineSntFilter, setActiveInlineSntFilter] = useState(true);
	const [showSmallSntInlineFilterButton, setShowSmallSntInlineFilterButton] = useState(false);
	const [showSmallRcvInlineFilterButton, setShowSmallRcvInlineFilterButton] = useState(false);
	const [inProgress, setInProgress] = useState(false);
	const [inProgressHistory, setInProgressHistory] = useState(false);
	const notesListRef = useRef(null);
	const [tabInfoIndex, setTabInfoIndex] = useState(0);

	const phoneDisplay =
		selectedConversation?.header?.type === "call" &&
		!selectedConversation?.messages?.some(message =>
			["transcript", "recording"].includes(message?.payload?.header?.context?.status)
		);

	useEffect(() => {
		/**
		 * When selected conv notes updates, scroll to bottom ....
		 * @param {function} data
		 */
		if (selectedConversation && selectedConversation?.notes) {
			scrollNotesToBottom();
		}
		setRcvAtt([]);
		setRcvAttAll([]);
		setSntAtt([]);
		setSntAttAll([]);
		setHistRcvAttAll([]);
		setHistRcvAtt([]);
		setShowSmallSntInlineFilterButton(false);
		setShowSmallRcvInlineFilterButton(false);
		if (selectedConversation?.header?.type === "call") {
			if (phoneDisplay === true) {
				setRightTab([3, 5].includes(tabInfoIndex) ? tabInfoIndex : 3);
			} else {
				setRightTab([0, 2].includes(tabInfoIndex) ? tabInfoIndex : 0);
			}
		} else {
			setRightTab(isCold ? 2 : tabInfoIndex);
		}
	}, [selectedConversation?.header.fID]);
	/**
	 *  Build the map of all attachments (inline and attachments) sent and received for one conversation
	 * @param {Function} useEffect
	 */
	useEffect(() => {
		if (selectedConversation?.messages) {
			const att = getRcvSntAtt(selectedConversation);

			setSntAtt(att.sntAtt);
			setRcvAtt(att.rcvAtt);
		}
	}, [selectedConversation?.messages]);

	/**
	 *  Build the map of all attachments (inline and attachments) sent and received for all the conversations
	 * @param {Function} useEffect
	 */
	useEffect(() => {
		const rcvSntAll = getRcvSntAttAll(rcvAtt, sntAtt, assistantconfig, selectedConversation);

		setRcvAttAll(rcvSntAll.rcvAttAll);
		setSntAttAll(rcvSntAll.sntAttAll);
	}, [sntAtt, rcvAtt]); // TODO: PIKA -> DANGER WILL BE CALLED TWICE IF BOTH ARE CHANGED !

	/**
	 * @param {Array} attachments to filter
	 * @returns the attachments array filtered without inlines under 10ko
	 */
	function filterInlineAttachment(attachments, sntORrcv) {
		let attachmentsFiltered = [];
		let convInlineLimitSize = assistantconfig?.limit?.conv_inline_filter || 10000;
		let smallButton = false;

		for (let att of attachments) {
			const contentDisposition = att.contentDisposition || att.type;
			// We build the array of attachments without inlines under 10ko
			if (contentDisposition !== "inline" || att.size > convInlineLimitSize) {
				attachmentsFiltered.push(att);
			} else {
				smallButton = true;
			}
		}
		if (sntORrcv === "snt") setShowSmallSntInlineFilterButton(smallButton);
		else setShowSmallRcvInlineFilterButton(smallButton);

		return attachmentsFiltered;
	}

	useEffect(() => {
		if (selectedConversation?.messages) {
			if (activeInlineRcvFilter) {
				setAttachmentsRcvFiltered(filterInlineAttachment(rcvAtt, "rcv"));
			} else {
				setAttachmentsRcvFiltered(rcvAtt);
			}
		}
	}, [rcvAtt, activeInlineRcvFilter]);

	useEffect(() => {
		if (selectedConversation?.messages) {
			if (activeInlineSntFilter) {
				setAttachmentsSntFiltered(filterInlineAttachment(sntAtt, "snt"));
			} else {
				setAttachmentsSntFiltered(sntAtt);
			}
		}
	}, [sntAtt, activeInlineSntFilter]);

	// Function to handle the inline filter toggle button
	const handleActiveInlineRcvFilter = () => {
		setActiveInlineRcvFilter(!activeInlineRcvFilter);
	};

	// Function to handle the inline filter toggle button
	const handleActiveInlineSntFilter = () => {
		setActiveInlineSntFilter(!activeInlineSntFilter);
	};

	useEffect(() => {
		if (conversations && convHistory?.length !== 0) {
			const histRcvAtt = convHistory
				.map(conv => conv.messages.filter(msg => msg.body))
				.flat()
				.filter(obj => obj.body.attachments)
				.map(obj => obj.body.attachments)
				.flat();
			setHistRcvAtt(histRcvAtt);
			if (histRcvAtt) {
				let arrUrl = histRcvAtt.map(oneAtt => {
					return { url: getACleanURI(oneAtt.url), filename: oneAtt.name };
				}); // PIKA: on pourraitconstruire ce tableau autrement pour avoir la fID... mais pas de changement pour le moment
				let histRcvAttAll = { assistantID: assistantconfig.assistantID, docs: arrUrl };
				setHistRcvAttAll(histRcvAttAll);
			}
		} else {
			setHistRcvAtt([]);
		}
	}, [convHistory]);

	const handleFetchReceiveAllAtt = (arr, from) => {
		if (from === "current") {
			setInProgress(true);
		} else if (from === "history") {
			setInProgressHistory(true);
		}
		dispatch(cockpitActions.getAllReceiveAttachments(arr, downloadSucceed, downloadFailed));
	};

	const handleFetchSentAllAtt = (arr, from) => {
		if (from === "current") {
			setInProgress(true);
		} else if (from === "history") {
			setInProgressHistory(true);
		}
		dispatch(cockpitActions.getAllReceiveAttachments(arr, downloadSucceed, downloadFailed));
	};

	const downloadSucceed = () => {
		setInProgressHistory(false);
		setInProgress(false);
		setSnackbarStatus({ open: true, message: i18n.t("COC.DownloadSucceed") });
	};

	const downloadFailed = () => {
		setInProgressHistory(false);
		setInProgress(false);
		setSnackbarStatus({
			open: true,
			message: i18n.t("COC.DownloadFailed"),
			severity: "error"
		});
	};

	/**
	 * When selected conv notes updates, scroll to bottom ....
	 * @param {function} data
	 */
	useEffect(() => {
		if (selectedConversation?.notes) {
			scrollNotesToBottom();
		}
	}, [selectedConversation]);

	const insertConvAnswerToEditor = answer => {
		if (answer !== undefined) {
			dispatch({
				type: C.SET_CONTENT_MESSAGE,
				payload: answer
			});
		}
	};

	const insertRichAnswerToEditor = (text, ansAttachments, ansSubject) => {
		dispatch({
			type: C.INSERT_HTML,
			payload: `${text}<br>`
		});

		if (!ansAttachments) ansAttachments = [];
		for (let att of ansAttachments) {
			dispatch({
				type: C.SET_CONTENT_MESSAGE,
				payload: att
			});
		}
		if (ansSubject?.length > 0)
			dispatch({
				type: C.SET_TOPIC_DRAFT,
				payload: ansSubject
			});
	};

	const scrollNotesToBottom = () => {
		if (notesListRef.current)
			notesListRef.current.scrollIntoView({
				behavior: "smooth",
				block: "end"
			});
	};

	const handleContactAnsDocSearchFilter = event => {
		dispatch(setContactAnsDocSearchFilter(event.target.value));
	};

	const handleClearContactAnsDocSearchFilter = () => {
		dispatch(setContactAnsDocSearchFilter(""));
	};

	const handleRightTabChange = (event, newTab) => {
		setRightTab(newTab);
		setTabInfoIndex(newTab);

		dispatch(setContactAnsDocSearchFilter(""));
	};

	const handleChangeAnswerLang = e => {
		dispatch(setAnswerLanguage(e.target.value));
	};

	const handleChangeAnswerTags = e => {
		dispatch(setTag(e.target.value));
	};

	// phoneDisplay is when the context/contact is central. When we have some messages "transcript" or "recording" we don't display the phone interface but the regular one with messages

	const TabInfoTabs = () => {
		return (
			<Tabs
				value={[0, 1, 2, 3, 4].includes(rightTab) ? rightTab : 0}
				onChange={handleRightTabChange}
				indicatorColor="primary"
				textColor="primary"
				variant="scrollable"
				scrollButtons="auto"
				style={{
					width: "100%"
				}}
			>
				<Tab
					style={{ minWidth: 20, fontFamily: "Roboto", fontSize: "14px", flex: 1 }}
					icon={
						<Tooltip title={i18n.t("COC.Contact")} placement="top">
							<InfoOutlined />
						</Tooltip>
					}
				/>
				<Tab
					style={{ minWidth: 20, fontFamily: "Roboto", fontSize: "14px", flex: 1 }}
					icon={
						<Tooltip title={i18n.t("COC.Response")} placement="top">
							<ReplyAllOutlined />
						</Tooltip>
					}
				/>

				<Tab
					style={{ minWidth: 20, fontFamily: "Roboto", fontSize: "14px", flex: 1 }}
					icon={
						<Tooltip title={i18n.t("COC.File")} placement="top">
							<AttachFileOutlined />
						</Tooltip>
					}
				/>
				<Tab
					style={{ minWidth: 20, fontFamily: "Roboto", fontSize: "14px", flex: 1 }}
					label={selectedConversation?.notes?.length > 0 ? selectedConversation?.notes?.length : ""}
					icon={
						<Tooltip title={i18n.t("COC.Notes")} placement="top">
							<AssignmentOutlined />
						</Tooltip>
					}
				/>
			</Tabs>
		);
	};

	return (
		<Grid item xs={xs} data-component-name="CockpitTabInfo">
			<Box width="100%" height="97vh" display="flex" flexDirection="column">
				<Box display="flex" alignItems="center" justifyContent="center" mt={1}>
					{isCold ? (
						<ColdTabInfoTabs handleRightTabChange={handleRightTabChange} />
					) : phoneDisplay ? (
						<TabInfoTabsCALL
							rightTab={rightTab}
							handleChangeRightTab={handleRightTabChange}
							callInfo={false}
						/>
					) : selectedConversation?.header?.type === "call" ? (
						<TabInfoTabsCALL
							rightTab={rightTab}
							handleChangeRightTab={handleRightTabChange}
							callInfo={true}
						/>
					) : (
						<TabInfoTabs />
					)}
				</Box>

				<Box flexWrap="wrap">
					<Box style={rightTab === 1 ? { display: "flex" } : { display: "block" }} m={1}>
						<TextField
							style={rightTab === 2 || rightTab === 0 ? { display: "none" } : {}}
							fullWidth
							label=""
							variant="outlined"
							size="small"
							value={contactAnsDocSearchFilter}
							onChange={handleContactAnsDocSearchFilter}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
								endAdornment: (
									<IconButton size="small" onClick={() => handleClearContactAnsDocSearchFilter()}>
										{contactAnsDocSearchFilter.trim().length > 0 && <ClearIcon />}
									</IconButton>
								)
							}}
						/>

						{rightTab === 1 && settings.getDisplayState("language") && (
							<Box style={{ display: "flex", flexDirection: "row" }}>
								<FormControl variant="outlined" size="small">
									<Select
										style={{
											marginLeft: "10px",
											textDecoration: "none"
										}}
										labelId="language_label"
										value={language}
										onChange={handleChangeAnswerLang}
									>
										{languages?.map(language => {
											return (
												<MenuItem key={`menu-option-${language}`} value={language}>
													<ListItemIcon>
														<img
															src={GetFlag(language.toUpperCase())}
															alt="CockpitTabInfo_flag"
															width="15"
															height="15"
															style={{ objectFit: "contain" }}
														/>
													</ListItemIcon>
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
								{!lod_.isEmpty(tagsResponses) && (
									<FormControl variant="outlined" size="small">
										<Select
											style={{
												marginLeft: "10px",
												textDecoration: "none"
											}}
											labelId="language_label"
											value={tag}
											onChange={handleChangeAnswerTags}
										>
											<MenuItem key={`menu-option-${tag}`} value={"none"}>
												{i18n.t("search.all")}
											</MenuItem>
											{tagsResponses?.map(tag => {
												return (
													<MenuItem key={`menu-option-${tag}`} value={tag}>
														{tag}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
								)}
							</Box>
						)}
					</Box>
				</Box>

				{(rightTab === 0 ||
					rightTab === 1 ||
					rightTab === 2 ||
					rightTab === 3 ||
					rightTab === 4) && (
					<Box flex={1} style={{ overflowX: "hidden", overflowY: "auto" }}>
						{rightTab === 0 && (
							// <Infos
							// 	contactAnsDocSearchFilter={contactAnsDocSearchFilter}
							// 	insertConvAnswerToEditor={insertConvAnswerToEditor}
							// 	insertRichAnswerToEditor={insertRichAnswerToEditor}
							// />
							<ContextCallinterface callHistory={true} />
						)}
						{rightTab === 1 && (
							<Answers
								insertConvAnswerToEditor={insertConvAnswerToEditor}
								insertRichAnswerToEditor={insertRichAnswerToEditor}
								setTagsResponses={setTagsResponses}
								tagSelected={tag}
								setTag={setTag}
							/>
						)}

						{rightTab === 2 && selectedConversation && (
							<Box
								height="100%"
								minHeight="100%"
								overflow="auto"
								display="flex"
								flexDirection="column"
								alignItems="center"
							>
								{!isCold ? (
									<>
										<AttachmentsContainer
											maxHeight="50%"
											attachmentsFiltered={attachmentsSntFiltered}
											allAttachments={sntAtt}
											title={i18n.t("COC.sntDocs")}
											emptyMsg={i18n.t("COC.noFile")}
											type="sent"
											handleFetchReceiveAllAtt={() => handleFetchSentAllAtt(sntAttAll, "current")}
											downloadAll={i18n.t("COC.downloadAll")}
											showSmallInlineFilterButton={showSmallSntInlineFilterButton}
											activeInlineFilter={activeInlineSntFilter}
											handleActiveInlineFilter={handleActiveInlineSntFilter}
											showSmallInline={i18n.t("COC.showSmallInline")}
											hideSmallInline={i18n.t("COC.hideSmallInline")}
											inProgress={inProgress}
											showAll={i18n.t("COC.showAll")}
										/>
										<AttachmentsContainer
											maxHeight="50%"
											attachmentsFiltered={attachmentsRcvFiltered}
											allAttachments={rcvAtt}
											title={i18n.t("COC.rcvDocs")}
											emptyMsg={i18n.t("COC.noFile")}
											type="receive"
											handleFetchReceiveAllAtt={() =>
												handleFetchReceiveAllAtt(rcvAttAll, "current")
											}
											downloadAll={i18n.t("COC.downloadAll")}
											showSmallInlineFilterButton={showSmallRcvInlineFilterButton}
											activeInlineFilter={activeInlineRcvFilter}
											handleActiveInlineFilter={handleActiveInlineRcvFilter}
											showSmallInline={i18n.t("COC.showSmallInline")}
											hideSmallInline={i18n.t("COC.hideSmallInline")}
											inProgress={inProgress}
										/>
									</>
								) : (
									<AttachmentsContainer
										maxHeight="50%"
										attachmentsFiltered={selectedConversation?.payload?.attachments}
										allAttachments={selectedConversation?.payload?.attachments}
										title={i18n.t("COC.sntDocs")}
										emptyMsg={i18n.t("COC.noFile")}
										type="sent"
										downloadAll={i18n.t("COC.downloadAll")}
										showAll={i18n.t("COC.showAll")}
										activeInlineFilter={activeInlineRcvFilter}
										showSmallInlineFilterButton={showSmallSntInlineFilterButton}
										inProgress={inProgress}
										customDownloadProcess={url => downloadColdFile(url)}
									/>
								)}
								{histRcvAtt.length > 0 && (
									<AttachmentsContainerHistory
										maxHeight="50%"
										children={histRcvAtt}
										heading={i18n.t("COC.history")}
										title={i18n.t("COC.rcvDocs")}
										emptyMsg={"No files"}
										handleFetchReceiveAllAtt={() =>
											handleFetchReceiveAllAtt(histRcvAttAll, "history")
										}
										type="receive"
										downloadAll={i18n.t("COC.downloadAll")}
										activeInlineFilter={activeInlineRcvFilter}
										showSmallInlineFilterButton={showSmallRcvInlineFilterButton}
										showSmallInline={i18n.t("COC.showSmallInline")}
										hideSmallInline={i18n.t("COC.hideSmallInline")}
										inProgressHistory={inProgressHistory}
									/>
								)}
							</Box>
						)}

						{rightTab === 3 && (
							<Box ref={notesListRef}>
								<NotesComponent
									searchFilter={true}
									contactAnsDocSearchFilter={contactAnsDocSearchFilter}
									notes={selectedConversation?.notes}
								/>
							</Box>
						)}

						{rightTab === 4 && (
							<ActionsComponent
								rcvAttAll={rcvAttAll}
								sntAttAll={sntAttAll}
								//builtInAnswers={sortedBuiltInAnswers}
							/>
						)}
					</Box>
				)}
				{rightTab === 3 &&
					(selectedConversation?.header.state === "ongoing" ||
						selectedConversation?.header.state === "resolved") && (
						<div>
							<Divider />
							<Box p={1} display="flex" alignItems="center">
								<NoteInputComponent />
							</Box>
						</div>
					)}
				<Box style={{ overflow: "auto" }}>
					{rightTab === 5 &&
						selectedConversation &&
						historyBACK.map((element, index) => (
							<HistoricList
								conversation={element}
								agent={selectedConversation?.agent}
								number={index}
								key={`historicList${index}`}
								history={history}
								minimalWidth={true}
							/>
						))}
				</Box>
			</Box>
		</Grid>
	);
};

const MemoizedCockpitTabInfo = memo(CockpitTabInfo);
export default MemoizedCockpitTabInfo;

export const getRcvSntAttAll = (rcvAtt, sntAtt, assistantconfig, selectedConversation) => {
	let rcvAttAll = [];
	let sntAttAll = [];

	if (rcvAtt.length !== 0) {
		let arrUrl = rcvAtt.map(oneAtt => {
			return {
				url: getACleanURI(oneAtt.url),
				filename: oneAtt.name,
				contentDisposition: oneAtt.contentDisposition,
				fID: selectedConversation?.header.fID
			};
		});
		rcvAttAll = { assistantID: assistantconfig.assistantID, docs: arrUrl };
	}
	if (sntAtt.length !== 0) {
		let arrUrl = sntAtt.map(oneAtt => {
			return {
				url: getACleanURI(oneAtt.url),
				filename: oneAtt.name,
				contentDisposition: oneAtt.contentDisposition,
				fID: selectedConversation?.header.fID
			};
		});
		sntAttAll = { assistantID: assistantconfig.assistantID, docs: arrUrl };
	}

	return { rcvAttAll, sntAttAll };
};

/**
 * Get attachments for received and sended messages
 */
export const getRcvSntAtt = selectedConversation => {
	let rcvAtt = [];
	let sntAtt = [];

	for (let message of selectedConversation.messages) {
		if (message) {
			try {
				// Check the version of the message
				// 		OldFormat -> via ORC, non pivot format
				// 		NewFormat -> via NYO, pivot format
				const isOldFormat =
					Array.isArray(message?.body?.attachments) ||
					Array.isArray(message?.body?.sent_attachments);
				const isNewFormat = Array.isArray(message?.payload?.payload?.attachments);

				const isReceived = message?.header?.source === "contact";

				let messageAttachments = [];

				if (isOldFormat) {
					// Received
					if (isReceived && Array.isArray(message?.body?.attachments)) {
						messageAttachments = message.body.attachments;
					}

					// Sent
					if (!isReceived && Array.isArray(message?.body?.sent_attachments)) {
						messageAttachments = message.body.sent_attachments;
					}
				}

				if (isNewFormat) {
					messageAttachments = message.payload.payload.attachments;
				}

				for (let att of messageAttachments) {
					att.mID = message.header?.mID;
					if (isReceived) {
						rcvAtt.push(att);
					} else {
						sntAtt.push(att);
					}
				}
			} catch (error) {
				// it can fail for a message
			}
		}
	}

	/**
	 * Return attachments
	 */
	return { rcvAtt, sntAtt };
};
